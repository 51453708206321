<template>

    <div class="col-12 mx-auto my-md-0 p-0" id="base">
        <i class="fa fa-close text-light m-3" @click="$emit('close')" style="font-size: 1.25em; position: absolute; z-index: 2;"></i> 

        <b-card
            overlay
            img-src="@/assets/portfolio/mengede/isometric-shadow.svg"
            img-alt="Isometric Design"
            bg-variant="transparent"
            text-variant="white"
            class="border-0 col-12 p-5 d-none d-md-block"
            body-class="d-flex flex-row align-items-start justify-content-end" >
            <div class="w-50 text-right">
                <b-img style="mix-blend-mode: hard-light; width: clamp(150px, 10vw, 1000px)" class="mb-4" src="@/assets/portfolio/mengede/logo.svg" />
                <b-card-title class="header-1"> Great Product Landing Page </b-card-title>
            </div>
        </b-card>
        <div class="mb-5 d-flex flex-column d-md-none">
            <b-img style="mix-blend-mode: hard-light; width: clamp(120px, 10vw, 1000px)" class="mt-4 mr-4 align-self-end" src="@/assets/portfolio/mengede/logo.svg" />
            <b-img style="width: 100%" class="" src="@/assets/portfolio/mengede/isometric-shadow.svg" />
            <b-card-title class="header-2 text-left w-75 align-self-end px-5"> Great Product Landing Page </b-card-title>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-wrap mb-5">            
            <b-card
                bg-variant="transparent"
                style="width: 30%; min-width: 300px;"
                class="border-0 text-left mx-auto mx-md-3" >
                <b-card-title class="header-2 mb-4"> Modern <span style="color: #00d288;"> Features</span> </b-card-title>
                
                <div class="">
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Multiple Language Support</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Real Time Synchronization</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Export to PDF / CSV</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Authorization</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Charts</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Search & Filter</p>
                </div>
            </b-card>

            <div class="p-0">
                <b-img src="@/assets/portfolio/mengede/screens.png" style="width: 100%" />
            </div>
        </div>

        <div class="d-flex mx-md-5 pt-md-5 border-top align-items-start justify-content-center flex-wrap mb-5">            
            <b-card
                bg-variant="transparent"
                style="width: 30%; min-width: 300px;"
                class="border-0 text-center" >
                <b-card-title class="header-2 mb-4"> <span style="color: #00d288;">Frontend</span> </b-card-title>
                
                <div class="">
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Vue</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Bootstrap</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">JavaScript</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">CSS</p>
                </div>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="width: 30%; min-width: 300px;"
                class="border-0 text-center" >
                <b-card-title class="header-2 mb-4"> <span style="color: #00d288;">Backend</span> </b-card-title>
                
                <div class="">
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">GoLang</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">Echo</p>
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">RESTFul API</p>
                </div>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="width: 30%; min-width: 300px;"
                class="border-0 text-center" >
                <b-card-title class="header-2 mb-4"> <span style="color: #00d288;">Database</span> </b-card-title>
                
                <div class="">
                    <p style="background: #CDF6E7; border-radius: 1.5em;" class="d-inline-block mx-1 px-4 py-2">MySQL</p>
                </div>
            </b-card>

            <b-card
                bg-variant="transparent"
                style="width: clamp(15em, 60vw, 700px);"
                class="border-0 text-center" >
                <b-card-title class="header-2 mb-4"> <span style="color: #00d288;">Project Duration</span> </b-card-title>
                
                <b-progress height="4em" class="d-none d-md-flex">
                    <b-progress-bar :value="60" style="background-color: #CDF6E7;" class="text-muted">
                        <span class="font-weight-bold h6 my-auto" style="font-size: clamp(10px, 1.1vw, 60px);">  Requirement + Design (4 Months) </span>
                    </b-progress-bar>
                    <b-progress-bar :value="40" style="background-color: #00d288;">
                        <span class="font-weight-bold h6 my-auto" style="font-size: clamp(10px, 1.1vw, 60px);"> Implementation (3 Months) </span>
                    </b-progress-bar>
                </b-progress>
            </b-card>
            <p class="my-2 p-3 text-muted font-weight-bold d-md-none" style="width: 20em; background-color: #CDF6E7;"> Requirement + Design (4 Months) </p>
            <p class="my-2 p-3 text-white font-weight-bold d-md-none" style="width: 20em; background-color: #00d288;"> Implementation (3 Months) </p>

        </div>

    </div>

</template>

<script>
export default {
    name: "Mengede",
    data() {
        return{
            
        }
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style>
#base {
    background-color: white !important;
    background: url('../assets/portfolio/mengede/back.svg') no-repeat;
    background-position: 0vw -45vw;
    background-size: cover;
    font-size: clamp(15px, 1.5vw, 60px);
    color: #4d4d4d;
}
</style>